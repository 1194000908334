import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import api from '../utils/api';

import SignUp from './SignUp';
import Logout from './Logout';
import SignIn from './SignIn';
import Checkout from './Checkout';
import RecoveryRequest from './PasswordRecoveryRequest';
import PasswordReset from './PasswordReset';
import NotFound from '../NotFound';

const stripePromise = loadStripe(process.env.STRIPE_API_KEY);

class Auth extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            plan: 'basic',
            name: '',
            surname: '',
            checkboxError: '',
            error: '',
            checkbox: false,
            loading: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.setState({
            error: '',
        });
    }

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/auth/sign-up"
                    render={() => (
                        <SignUp
                            handleInputChange={this._handleInputChange}
                            handleCheckboxChange={this._handleCheckboxChange}
                            email={this.state.email}
                            password={this.state.password}
                            error={this.state.error}
                            checkboxError={this.state.checkboxError}
                            sign={this._signupStep1}
                            plan={this.state.plan}
                            name={this.state.name}
                            surname={this.state.surname}
                            checkbox={this.state.checkbox}
                            user={this.props.user}
                            loading={this.state.loading}
                        />
                    )}
                />
                <Route
                    exact
                    path="/auth/sign-in"
                    render={() => (
                        <SignIn
                            handleInputChange={this._handleInputChange}
                            email={this.state.email}
                            password={this.state.password}
                            error={this.state.error}
                            sign={this._sign}
                            user={this.props.user}
                        />
                    )}
                />
                <Route
                    exact
                    path="/auth/logout"
                    render={() => <Logout resetUser={this.props.resetUser} />}
                />
                <Route exact path="/auth/recover" render={() => <RecoveryRequest />} />
                <Route
                    exact
                    path="/auth/recover/:query"
                    render={() => <PasswordReset location={this.props.location} />}
                />
                <Route
                    exact
                    path="/auth/checkout"
                    render={() => (
                        <Checkout success={this._checkoutSuccess} cancel={this._checkoutCancel} />
                    )}
                />

                <Route component={NotFound} />
            </Switch>
        );
    }

    _handleInputChange = (key, newValue) => {
        this.setState({
            [key]: newValue,
        });
    };

    _handleCheckboxChange = () => {
        this.setState((prevState) => ({
            checkbox: !prevState.checkbox,
            // error: '',
        }));
    };

    _sign = () => {
        this.setState({
            error: '',
        });

        let email = this.state.email.toLowerCase();

        api.post('/api/auth/sign-in', { email, password: this.state.password })
            .then((data) => {
                localStorage.setItem('identity', data.token);
                this.props.setUser();
                this.props.history.push('/dashboard');
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _signupStep1 = () => {
        this.setState({
            error: '',
        });

        let email = this.state.email.toLowerCase();

        if (
            this.state.email &&
            this.state.name &&
            this.state.surname &&
            this.state.password &&
            this.state.checkbox
        ) {
            this.setState({
                loading: true,
            });
        }

        api.post(`/api/auth/sign-up`, {
            email,
            password: this.state.password,
            plan: this.state.plan,
            name: this.state.name,
            surname: this.state.surname,
            checkbox: this.state.checkbox,
        })
            .then((data) => {
                localStorage.setItem('identity', data.token);
                this.props.setUser();
                this.props.history.push('/dashboard');

                this.setState({
                    loading: false,
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                    loading: false,
                });
            });
    };

    _handleStripeCheckout = (sessionId) => {
        stripePromise.then((stripe) => {
            stripe
                .redirectToCheckout({
                    sessionId: sessionId,
                })
                .then(() => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    };

    _checkoutSuccess = (sessionId) => {
        // api.post(`/api/auth/update-payment`, { session_id: sessionId }).then((data) => {
        //     localStorage.setItem('identity', data.token);
        //     this.props.setUser();
        //     this.props.history.push('/dashboard');
        // });
    };

    _checkoutCancel = () => {};
}

export default withRouter(Auth);
