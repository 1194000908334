import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import smoothscroll from 'smoothscroll-polyfill';
import imgMiddle from '../assets/img1.png';
import imgTop from '../assets/img2.png';
import check from '../assets/check-img.png';

import api from './utils/api';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            checkbox: false,
            emailSubmitted: false,
            windowWidth: window.innerWidth,
            activeClass: 'top',
        };
    }

    componentDidMount() {
        smoothscroll.polyfill();

        //for measuring the size of the window
        window.addEventListener('resize', this._handleResize);

        //for detecting website scroll
        window.addEventListener('scroll', () => {
            let activeClass = 'bottom';
            if (window.scrollY === 0) {
                activeClass = 'top';
            }

            this.setState({ activeClass });
        });
    }

    componentWillUnMount() {
        window.addEventListener('resize', this._handleResize);
    }

    render() {
        return (
            <main className="container">
                <div className="home">
                    <Helmet>
                        <title>epicmatic | Epics and user stories made easier.</title>
                    </Helmet>
                    <div className="home-section" id="start">
                        <div className="home-wrapper home-column home-top">
                            <div className="home-left">
                                <h1 className="slogan">
                                    Create your epics and user stories using artificial intelligence.
                                </h1>
                                <Link to="/auth/sign-up">
                                    <button className="about-btn">
                                        start now - it's free!
                                        <div className="zigzag"></div>
                                    </button>
                                </Link>
                            </div>
                            <div className="home-right">
                                <img
                                    src={imgTop}
                                    className={`img-home ${this.state.activeClass}`}
                                    alt="Blue robot sitting at the desk in front of the computer"
                                />
                            </div>
                        </div>
                        <Link smooth to="/#about" className="scroll-btn">
                            find out more
                            <FontAwesomeIcon icon={faChevronDown} />
                        </Link>
                    </div>
                    <div className="about-section" id="about">
                        <div className="home-wrapper home-column home-top">
                            <div className="home-left">
                                <img
                                    src={imgMiddle}
                                    className="img-middle"
                                    alt="Blue robot showing text on the whiteboard"
                                />
                            </div>

                            <div className="home-right">
                                <div className="form-txt-big" style={{ letterSpacing: '1.5px' }}>
                                    Be epic - work smart with the help of AI
                                </div>
                                <div className="form-txt-small">
                                    Epics provide a framework for organizing and prioritizing work. By
                                    breaking down large initiatives into smaller, more manageable pieces,
                                    teams can focus on delivering value in a more incremental and
                                    iterative way. It allows for greater flexibility and adaptability in
                                    the face of changing requirements and priorities.
                                    <div style={{ marginTop: '10px' }}>
                                        Preparing epics and user stories is often a burdensome and
                                        time-consuming task, but artificial intelligence can make this
                                        process significantly easier.
                                    </div>
                                    <br />
                                    Don't waste any more time - start creating your epics with the
                                    support of AI!
                                </div>
                                <Link to="/auth/sign-up">
                                    <button className="action-btn">start now</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rotate">
                    <div className="phone"></div>
                    <div className="message">please rotate your device</div>
                </div>
            </main>
        );
    }

    _handleChange = (e) => {
        this.setState({ email: e.target.value, error: '' });
    };

    _handleSubmit = (e) => {
        if (this.state.checkbox === true) {
            let email = { email: this.state.email };

            api.post('/api/emails/add', email)
                .then((data) => {
                    this.setState({
                        email: '',
                        error: '',
                        checkbox: false,
                        emailSubmitted: true,
                    });

                    setTimeout(() => {
                        this.setState({
                            emailSubmitted: false,
                        });
                    }, 5000);
                })
                .catch((err) => {
                    this.setState({
                        error: err.description,
                    });
                });
        } else {
            this.setState({ error: 'You have to accept our Terms and Privacy Policy.' });
        }

        e.preventDefault();
    };

    _handleCheckbox = () => {
        this.setState((prevState) => ({
            checkbox: !prevState.checkbox,
            error: '',
        }));
    };

    _handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };
}

export default Home;
