import React from 'react';
import { Helmet } from 'react-helmet';
import { HashLink as Link } from 'react-router-hash-link';
import check from '../assets/check-img.png';
import logo from '../assets/logo.png';

const About = (props) => {
    return (
        <main className="contact-container imprint-container container">
            <Helmet>
                <title>About | epicmatic</title>
            </Helmet>

            <div className="contact-form faq-wrapper about">
                <h1
                    className="contact-txt-big"
                    style={{ margin: '-20px 0 20px', alignSelf: 'flex-start' }}
                >
                    Why EPICMATIC?
                </h1>

                <h3 className="about-title" style={{ marginTop: '5px' }}>
                    The importance of epic
                </h3>
                <div className="about-txt">
                    In agile software development, an epic is a large and complex piece of work that can
                    be broken down into smaller, more manageable user stories. An agile epic is a
                    high-level user story that captures the overall objective of a set of related user
                    stories. It is a strategic initiative that helps to communicate the broader vision
                    and goals of the project.
                    <br /> <br />
                    Epics help to align the development team with the broader organizational goals and
                    objectives. By providing a clear vision of what needs to be accomplished, they can
                    help to ensure that the team is working towards a common goal and is making progress
                    towards delivering value to stakeholders.
                </div>
                <h3 className="about-title">
                    Our goal is to provide you with a tool that will allow you to create epics easily and
                    efficiently
                </h3>
                <div className="about-txt">
                    Every project manager and team lead knows that writing epics is often a very
                    challenging and time-consuming task. It requires:
                    <ul>
                        <li style={{ fontWeight: 'normal' }}>
                            <img src={check} className="check-img" />
                            <div>
                                <b>Defining the scope</b>
                                <br />
                                Epics are big, high-level user stories that define a set of requirements
                                for a particular feature or product. It can be difficult to determine the
                                scope of an epic, as it often involves multiple stakeholders and can span
                                several sprints or releases.
                            </div>
                        </li>
                        <li>
                            <img src={check} className="check-img" />
                            <div>
                                <b>Balancing detail and brevity</b>
                                <br />
                                Epics should be detailed enough to guide the development team but not so
                                detailed that they become too prescriptive or limit creativity. Finding
                                the right balance can be challenging.
                            </div>
                        </li>
                        <li>
                            <img src={check} className="check-img" />
                            <div>
                                <b>Managing dependencies</b>
                                <br />
                                Epics often involve multiple teams and dependencies, which can make it
                                difficult to coordinate and manage the work across the organization.
                            </div>
                        </li>
                        <li>
                            <img src={check} className="check-img" />
                            <div>
                                <b>Prioritization</b>
                                <br />
                                Epics may include a large number of features or requirements, and it can
                                be challenging to prioritize them in a way that aligns with the overall
                                product strategy and delivers the most value to customers.
                            </div>
                        </li>
                    </ul>
                    <br />
                    With the help of artificial intelligence, the process of creating epics and user
                    stories can get significantly easier. EPICMATIC, based on the provided technical
                    stack and task description, will generate for you:
                    <ul>
                        <li style={{ fontWeight: 'normal' }}>
                            <img src={check} className="check-img" />
                            epic title and user stories,
                        </li>
                        <li>
                            <img src={check} className="check-img" />
                            acceptance criteria for each story,
                        </li>
                        <li>
                            <img src={check} className="check-img" />
                            story points for each story and the total number of points for a whole epic.
                        </li>
                    </ul>
                    Then all you will have to do is to verify the correctness of generated assumptions
                    and adapt the epic to the criteria and requirements specific to your project. Yes,
                    it's that easy!
                </div>
                <br />

                {props.user ? null : (
                    <div className="btn-wrapper">
                        <Link to="/auth/sign-up">
                            <button className="start-btn">start now for free</button>
                        </Link>
                    </div>
                )}

                <div style={{ marginTop: '20px', fontSize: '14px', alignSelf: 'center' }}>
                    Do you have questions? Check our{' '}
                    <Link to="/faq" className="link-orange">
                        FAQ
                    </Link>{' '}
                    section.
                </div>
                <div style={{ marginTop: '5px', fontSize: '14px', alignSelf: 'center' }}>
                    To find out more about our pricing {/* plans  */}
                    go to the{' '}
                    <Link to="/pricing" className="link-orange">
                        pricing
                    </Link>{' '}
                    page.
                </div>
            </div>
        </main>
    );
};

export default About;
