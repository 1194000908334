import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import logo from '../assets/logo.png';

const Navigation = (props) => {
    return (
        <nav className="navigation">
            <NavLink className="" to="/#start" smooth>
                <img src={logo} className="logo" alt="logo" />
            </NavLink>
            <div className="nav-wrap">
                <div>
                    <NavLink className="link nav-link" to="/about" smooth activeClassName="join-btn">
                        about
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                {!props.user ? (
                    <div>
                        <NavLink className="link nav-link" to="/pricing" activeClassName="join-btn">
                            pricing
                        </NavLink>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                ) : null}
                {props.user ? (
                    <div>
                        <NavLink className="link nav-link" to="/dashboard" activeClassName="join-btn">
                            dashboard
                        </NavLink>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink className="link nav-link" to="/account" activeClassName="join-btn">
                            account
                        </NavLink>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink className="link nav-link" to="/auth/logout">
                            logout
                        </NavLink>
                    </div>
                ) : (
                    <div>
                        <NavLink className="link nav-link" to="/auth/sign-in" activeClassName="join-btn">
                            login
                        </NavLink>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink
                            className="link nav-link"
                            to="/auth/sign-up"
                            activeClassName="join-btn"
                            style={{
                                color: '#917c2a',
                            }}
                        >
                            sign up
                        </NavLink>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navigation;
