import React from 'react';
import ReactTilt from 'react-universal-tilt';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

class SignIn extends React.Component {
    render() {
        return (
            <main className="contact-container imprint-container container">
                <Helmet>
                    <meta name="robots" content="noindex" />
                    <title>Imprint | epicmatic</title>
                </Helmet>
                <div className="contact-form">
                    <div className="contact-txt-big" style={{ marginBottom: '20px' }}>
                        Imprint.
                    </div>
                    <div className="imprint-txt" style={{ marginBottom: '15px' }}>
                        Angaben gemäß § 5 TMG
                    </div>
                    <div className="company-big">epicmatic.com</div>
                    <div className="company-small" style={{ marginBottom: '15px' }}>
                        Katarzyna Roszak
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        Kantstraße 65
                        <br />
                        10627, Berlin, Germany
                    </div>
                    <div className="imprint-contact" style={{ marginBottom: '15px' }}>
                        contact: <a href="mailto:info@epicmatic.com">info@epicmatic.com</a>
                    </div>
                    <div className="imprint-txt">
                        Umsatzsteuer-ID nach § 27a Umsatzsteuergesetz: DE350505545
                    </div>
                    <div className="imprint-txt">
                        Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV: Katarzyna Roszak
                    </div>
                </div>
            </main>
        );
    }
}

export default SignIn;
