import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import check from '../assets/check-img.png';

class Pricing extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        return (
            <main className="pricing-container container contact-container">
                <Helmet>
                    <title>Pricing | epicmatic</title>
                </Helmet>

                <div className="pricing-wrapper">
                    <div className="plans">
                        <div className="plan">
                            <div className="price-title">free</div>
                            <div className="bottom-container">
                                <div
                                    className="price-circle"
                                    style={{ fontSize: '20px', lineHeight: '20px' }}
                                >
                                    FREE TRIAL
                                </div>

                                <ul className="footer-list terms">
                                    <li>
                                        <div className="section pricing-sec">
                                            <img src={check} className="check-img" />2 500 token credits
                                            (one time allowance)
                                        </div>
                                    </li>
                                    <li>
                                        <div className="section pricing-sec">
                                            <img src={check} className="check-img" />
                                            <u>no credit card required</u>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="section pricing-sec">
                                            <img src={check} className="check-img" />
                                            limited support
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="plan">
                            <div className="price-title">basic</div>
                            <div className="bottom-container">
                                <div className="price-circle" style={{ backgroundColor: '#53636d' }}>
                                    <div className="price">9.90</div>
                                    <div className="currency">€/month</div>
                                </div>

                                <ul className="footer-list terms">
                                    <li>
                                        <div className="section pricing-sec">
                                            <img src={check} className="check-img" />
                                            20 000 token credits per month*
                                        </div>
                                    </li>
                                    <li>
                                        <div className="section pricing-sec">
                                            <img src={check} className="check-img" />
                                            full support
                                        </div>
                                    </li>
                                </ul>
                                <div className="det">*credits do not sum up</div>
                            </div>
                        </div>

                        <div className="plan">
                            <div className="price-title">pro</div>
                            <div className="bottom-container">
                                <div className="price-circle" style={{ backgroundColor: '#8e7002' }}>
                                    <div className="price">24.90</div>
                                    <div className="currency">€/month</div>
                                </div>

                                <ul className="footer-list terms">
                                    <li>
                                        <div className="section pricing-sec">
                                            <img src={check} className="check-img" />
                                            100 000 token credits per month*
                                        </div>
                                    </li>
                                    <li>
                                        <div className="section pricing-sec">
                                            <img src={check} className="check-img" />
                                            full support
                                        </div>
                                    </li>
                                </ul>
                                <div className="det">*credits do not sum up</div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.user ? null : (
                    <Link smooth="true" to="/auth/sign-up">
                        <button className="pricing-btn">subscribe for free</button>
                    </Link>
                )}

                <div style={{ margin: '30px 0 20px', fontSize: '14px' }}>
                    Do you have payment related questions? Check our{' '}
                    <Link to="/faq" className="link-orange">
                        FAQ
                    </Link>{' '}
                    page.
                </div>
            </main>
        );
    }
}

export default Pricing;
