import React, { Component } from 'react';
import articles from './articles.json';
import { HashLink as Link } from 'react-router-hash-link';
import { FaArrowRight } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

class Article extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const title = articles.articles.find((el) => el.link === this.props.title).title;

        return (
            <main className="contact-container blog-container-article">
                <Helmet>
                    <title>{title} | epicmatic </title>
                </Helmet>
                {articles.articles
                    .filter((el) => el.link === this.props.title)
                    .map((article, index) => (
                        <div className="article" key={index}>
                            <h1 className="post-title">{article.title}</h1>
                            <div
                                className="text"
                                dangerouslySetInnerHTML={{ __html: article.contentTop }}
                            ></div>
                            {article.header1 ? (
                                <div>
                                    <h2 className="header">{article.header1}</h2>
                                    <div
                                        className="text"
                                        dangerouslySetInnerHTML={{ __html: article.content1 }}
                                    ></div>
                                </div>
                            ) : null}
                            {article.header2 ? (
                                <div>
                                    <h2 className="header">{article.header2}</h2>
                                    <div
                                        className="text"
                                        dangerouslySetInnerHTML={{ __html: article.content2 }}
                                    ></div>
                                </div>
                            ) : null}
                            {article.header3 ? (
                                <div>
                                    <h2 className="header">{article.header3}</h2>
                                    <div
                                        className="text"
                                        dangerouslySetInnerHTML={{ __html: article.content3 }}
                                    ></div>
                                </div>
                            ) : null}
                            <br /> <br />
                            <div className="blog-links">
                                <Link to="/blog" className="privacy-link-color blog-link">
                                    <div>go back to blog</div>
                                </Link>
                                <Link
                                    to={
                                        articles.articles
                                            .map((object) => object.link)
                                            .indexOf(article.link) === 11
                                            ? `/blog/${articles.articles[0].link}`
                                            : `/blog/${
                                                  articles.articles[
                                                      articles.articles
                                                          .map((object) => object.link)
                                                          .indexOf(article.link) + 1
                                                  ].link
                                              }`
                                    }
                                    className="privacy-link-color blog-link"
                                >
                                    <div>
                                        read next article <FaArrowRight />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
            </main>
        );
    }
}

export default Article;
