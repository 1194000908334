import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

class SignUp extends React.Component {
    componentDidMount() {
        this.props.handleInputChange('email', '');
        this.props.handleInputChange('password', '');
        this.props.handleInputChange('name', '');
        this.props.handleInputChange('surname', '');
        this.props.handleInputChange('plan', 'basic');
    }

    render() {
        if (this.props.user) return <Redirect to="/dashboard" />;

        return (
            <main className="signup-container">
                <Helmet>
                    <meta name="robots" content="noindex" />
                    <title>Sign Up | epicmatic</title>
                </Helmet>
                <div className="contact-form">
                    <div className="contact-txt-big">Sign up.</div>
                    <div class="credit-txt">no credit card required</div>
                    <div className="log-details-container">
                        <input
                            className="form-inp"
                            type="email"
                            placeholder="email address"
                            value={this.props.email}
                            onChange={(e) => this.props.handleInputChange('email', e.target.value)}
                            onKeyPress={this._onKeyPress}
                            required
                        />
                        <input
                            className="form-inp"
                            type="name"
                            placeholder="name"
                            value={this.props.name}
                            onChange={(e) => this.props.handleInputChange('name', e.target.value)}
                            onKeyPress={this._onKeyPress}
                            required
                        />
                        <input
                            className="form-inp"
                            type="surname"
                            placeholder="surname"
                            value={this.props.surname}
                            onChange={(e) => this.props.handleInputChange('surname', e.target.value)}
                            onKeyPress={this._onKeyPress}
                            required
                        />
                        <input
                            className="form-inp"
                            type="password"
                            placeholder="password"
                            minLength="6"
                            maxLength="20"
                            value={this.props.value}
                            onChange={(e) => this.props.handleInputChange('password', e.target.value)}
                            onKeyPress={this._onKeyPress}
                            required
                        />
                        <label className="checkbox-label checkbox path">
                            <input
                                className="sign-checkbox"
                                type="checkbox"
                                checked={this.props.checkbox}
                                onChange={this.props.handleCheckboxChange}
                                onKeyPress={this._onKeyPress}
                            />
                            <svg viewBox="0 0 21 21">
                                <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                            </svg>
                            I accept
                            <Link to="/terms" className="home-link" style={{ marginRight: '3px' }}>
                                Terms
                            </Link>
                            and
                            <Link to="/privacy-policy" className="home-link">
                                Privacy Policy
                            </Link>
                            .
                        </label>
                        <button className="form-btn sign-btn" onClick={() => this.props.sign()}>
                            {this.props.loading ? (
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            ) : (
                                'sign up'
                            )}
                        </button>
                        {this.props.error ? <div className="sign-error">{this.props.error}</div> : null}
                        {this.props.checkboxError ? (
                            <div className="sign-error">{this.props.checkboxError}</div>
                        ) : null}
                        <div className="form-info" style={{ fontSize: '14px' }}>
                            Already have an account?{' '}
                            <Link to="/auth/sign-in" className="home-link">
                                Login
                            </Link>
                            .
                        </div>
                    </div>
                </div>
            </main>
        );
    }

    _onKeyPress = (e) => {
        if (e.which === 13) {
            this.props.sign();
        }
    };
}

export default SignUp;
