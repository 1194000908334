import React from 'react';
import { Redirect } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import api from '../utils/api';

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            password1: '',
            password2: '',
            changed: false,
        };
    }

    render() {
        if (this.props.user) return <Redirect to="/dashboard" />;

        return (
            <main className="contact-container container">
                <Helmet>
                    <meta name="robots" content="noindex" />
                    <title>Password Recovery | epicmatic</title>
                </Helmet>
                {!this.state.changed ? (
                    <div className="contact-form">
                        <div className="contact-txt-big" style={{ marginBottom: '20px' }}>
                            Reset password.
                        </div>
                        <div className="recovery-txt">Please enter your new password below.</div>

                        <input
                            type="password"
                            placeholder="new password"
                            value={this.props.password1}
                            onChange={(e) => this._handleInputChange('password1', e.target.value)}
                            onKeyPress={this._onKeyPress}
                            minLength="6"
                            maxLength="20"
                            required
                        />

                        <input
                            type="password"
                            placeholder="repeat new password"
                            value={this.props.password2}
                            onChange={(e) => this._handleInputChange('password2', e.target.value)}
                            onKeyPress={this._onKeyPress}
                            minLength="6"
                            maxLength="20"
                            required
                        />

                        <button className="form-btn sign-btn" onClick={() => this._changePassword()}>
                            change password
                        </button>
                        <div className="sign-error">{this.state.error}</div>
                        {/* <div className="circle-sign"></div>
                        <div className="circle2-sign"></div> */}
                    </div>
                ) : (
                    <div className="contact-form" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="circle-wrapper">
                            <div className="success-circle"></div>
                        </div>
                        <div className="contact-txt-big" style={{ margin: '15px 0 20px' }}>
                            Success.
                        </div>
                        <div className="recovery-txt" style={{ textAlign: 'center', marginBottom: '0' }}>
                            Your password has been changed. You can login using new credentials.
                        </div>
                        <div className="btn-wrapper">
                            <Link to="/auth/sign-in">
                                <button className="start-btn">login</button>
                            </Link>
                        </div>
                    </div>
                )}
            </main>
        );
    }

    _handleInputChange = (key, newValue) => {
        this.setState({
            [key]: newValue,
        });
    };

    _changePassword = () => {
        let pass1 = this.state.password1;
        let pass2 = this.state.password2;

        const values = queryString.parse(this.props.location.search);
        const userId = values.id;
        const token = values.token;

        if (!pass1 || !pass2 || pass1 !== pass2) {
            this.setState({
                error: 'Both passwords have to be the same.',
            });
        } else if (pass1.length < 6) {
            this.setState({
                error: 'Your password should be at least 6 characters long.',
            });
        } else {
            api.post('/api/auth/reset-password', { password: pass1, userId, token })
                .then((data) => {
                    this.setState({
                        changed: true,
                        error: '',
                    });
                })
                .catch((err) => {
                    this.setState({
                        error: err.description,
                    });
                });
        }
    };
}

export default PasswordReset;
