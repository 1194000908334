import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer-container">
            <span className="footer-rights">©2023 epicmatic. All rights reserved.</span>
            <div className="footer-links">
                {/* <Link className="link footer-link" to="/blog">
                    blog
                </Link> */}
                <Link className="link footer-link" to="/pricing">
                    pricing
                </Link>
                <Link className="link footer-link" to="/faq">
                    faq
                </Link>
                <Link className="link footer-link" to="/terms">
                    terms
                </Link>
                <Link className="link footer-link" to="/privacy-policy">
                    privacy policy
                </Link>
                <Link className="link footer-link" to="/contact">
                    contact
                </Link>
                <Link className="link footer-link" to="/imprint">
                    imprint
                </Link>
            </div>
        </footer>
    );
};

export default Footer;
