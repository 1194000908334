import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import { loadStripe } from '@stripe/stripe-js';
import api from '../utils/api';
import TrelloClient, { Trello } from 'react-trello-client';

const stripePromise = loadStripe(process.env.STRIPE_API_KEY);

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            description: '',
            generatedContent: '',
            error: '',
            loading: false,
            descriptionCounter: 350,
            stackCounter: 100,
            loadingEpics: true,
        };
    }

    componentDidMount() {
        //refreshing user to be sure that displayed amount of credits is correct
        this._refreshUser();

        //get user's API keys
        this._getIntegrations();

        //get user's epics
        this._getPreviousEpics();
    }

    render() {
        if (!this.props.user) return <Redirect to="/auth/sign-in" />;

        //create an array from AI response
        let epicArr;

        if (this.state.generatedContent) {
            epicArr = this.state.generatedContent.split('\n');
        }

        //checking if user provided API key
        let isTrelloApiProvided;
        let isShortcutApiProvided;

        if (this.state.integrations) {
            isTrelloApiProvided = this.state.integrations.some((el) => el.integrationName === 'trello');
            isShortcutApiProvided = this.state.integrations.some(
                (el) => el.integrationName === 'shortcut'
            );
        }

        return (
            <main className="user-container container">
                <Helmet>
                    <title>Dashboard | epicmatic</title>
                </Helmet>

                <div className="epic-wrapper">
                    <div className="form-container">
                        <div className="section-txt" style={{ marginBottom: '45px' }}>
                            Describe
                            <div style={{ fontSize: '14px', color: '#53636d' }}>
                                Available token credit: {this.props.user.usageLimit}
                            </div>
                        </div>

                        <input
                            type="text"
                            value={this.state.name}
                            onChange={(evt) => this._handleInputChange('name', evt.target.value)}
                            className="form-input"
                            placeholder="eg. Android, Kotlin"
                            id="name"
                            autoComplete="off"
                            required
                            maxLength={100}
                        />
                        <label htmlFor="name" className="input-label">
                            <span className="input-txt">
                                Your stack:*
                                <div className="create-form-info">
                                    Available {this.state.stackCounter}/100 characters.
                                </div>
                            </span>
                        </label>
                        <br />
                        <br />
                        <br />
                        <br />
                        <textarea
                            value={this.state.description}
                            placeholder="Describe your task in detail, including all key points."
                            onChange={(evt) => this._handleInputChange('description', evt.target.value)}
                            id="msg"
                            className="msg-input"
                            autoComplete="off"
                            rows={5}
                            required
                            maxLength={350}
                        />
                        <label htmlFor="msg" className="msg-label">
                            <span className="msg-txt" style={{ marginTop: '-70px' }}>
                                Write the description of a task:*
                                <div className="create-form-info">
                                    Available {this.state.descriptionCounter}/350 characters.
                                </div>
                            </span>
                        </label>

                        <br />
                        <button className="create-btn" onClick={this._handleFormSubmit}>
                            {this.state.loading ? (
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            ) : (
                                'Create epic'
                            )}
                        </button>
                        <br />
                        <div className="error">{this.state.error}</div>

                        <div className="section-txt" style={{ marginBottom: '10px', marginTop: '30px' }}>
                            Past epics
                        </div>
                        {this.state.loadingEpics ? (
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (
                            <div>
                                {this.state.epics.length === 0 ? (
                                    <div>No epics</div>
                                ) : (
                                    <div>
                                        {this.state.epics.map((el, index) => {
                                            return (
                                                <div key={index} className="single-epic">
                                                    <div className="epic-request">
                                                        <div style={{ marginBottom: '5px' }}>
                                                            <b>Stack:</b> {el.stack}
                                                        </div>
                                                        <div>
                                                            <b>Description:</b> {el.description}
                                                        </div>
                                                    </div>
                                                    <div className="epic-actions">
                                                        <button onClick={() => this._displayDetails(el)}>
                                                            details
                                                        </button>
                                                        <button
                                                            onClick={() => this._deleteEpic(el._id)}
                                                            style={{
                                                                backgroundColor: 'transparent',
                                                                color: '#799da8',
                                                            }}
                                                        >
                                                            delete
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {!this.state.generatedContent ? null : (
                        <div className="epic-container">
                            <div className="section-txt" style={{ marginBottom: '15px' }}>
                                Epic
                            </div>

                            <div id="epic">
                                {epicArr.map((el, index, array) => {
                                    if (el.includes('Epic: ')) {
                                        return (
                                            <div
                                                className="epic-section"
                                                style={{ marginBottom: '10px' }}
                                                key={index}
                                            >
                                                <b>Your Epic:</b>
                                                {el.replace('Epic:', '')}
                                            </div>
                                        );
                                    }

                                    if (el.includes('User Stories:')) {
                                        return (
                                            <div
                                                className="epic-section"
                                                style={{ marginTop: '15px' }}
                                                key={index}
                                            >
                                                <b>{el}</b>
                                            </div>
                                        );
                                    }

                                    if (
                                        /1. |2. |3. |4. |5. |6. |7. |8. |9. |10. |11. |12. |13. |14. |15. |16. |17. |18. |19. |20. |21. |22. |23. |24. |25. /.test(
                                            el
                                        )
                                    ) {
                                        return (
                                            <div className="story-wrapper" key={index}>
                                                <div className="story-bullet">
                                                    {el.slice(0, 2).replace('.', '')}
                                                </div>
                                                <b className="epic-story">{el.substring(3)}</b>
                                            </div>
                                        );
                                    }

                                    if (el.includes('Total Story Points:')) {
                                        return (
                                            <div className="epic-points-total" key={index}>
                                                <b>{el}</b>
                                            </div>
                                        );
                                    }

                                    if (el.includes('Story Points:')) {
                                        return (
                                            <div className="epic-points" key={index}>
                                                <b>{el}</b>
                                            </div>
                                        );
                                    }

                                    if (el.includes('Acceptance Criteria:')) {
                                        return (
                                            <div
                                                className="epic-criteria"
                                                style={{ fontSize: '16px' }}
                                                key={index}
                                            >
                                                <b>{el}</b>
                                            </div>
                                        );
                                    }

                                    if (el.trimStart().charAt(0) === '-') {
                                        return (
                                            <div className="epic-criteria" key={index}>
                                                <b>{el.replace('-', '●')}</b>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    )}

                    {!this.state.generatedContent ? null : (
                        <div className="integration-wrapper">
                            <button
                                className="integration-btn"
                                onClick={this._handleTrello}
                                disabled={!isTrelloApiProvided}
                            >
                                create in trello
                            </button>

                            <button
                                className="integration-btn"
                                onClick={this._handleShortcut}
                                disabled={!isShortcutApiProvided}
                            >
                                create in shortcut
                            </button>

                            <button
                                className="copy-btn"
                                onClick={() =>
                                    navigator.clipboard.writeText(this.state.generatedContent)
                                }
                            >
                                copy epic
                            </button>
                        </div>
                    )}
                </div>
            </main>
        );
    }

    _handleInputChange = (key, newValue) => {
        this.setState({
            [key]: newValue,
        });

        if (key === 'description') {
            let descriptionCounter = 350 - newValue.length;

            this.setState({
                descriptionCounter,
            });
        }

        if (key === 'name') {
            let stackCounter = 100 - newValue.length;

            this.setState({
                stackCounter,
            });
        }
    };

    _handleFormSubmit = () => {
        this.setState({
            error: '',
        });

        let page = {};

        if (
            !this.state.name.replace(/\s/g, '').length ||
            !this.state.description.replace(/\s/g, '').length
        ) {
            this.setState({
                error: 'Please fill in all required fields.',
            });
        } else {
            this.setState({
                loading: true,
            });

            page.name = this.state.name;
            page.description = this.state.description;

            api.post('/api/epic/generate', page)
                .then((data) => {
                    this.setState({
                        generatedContent: data.response,
                        loading: false,
                    });

                    this._refreshUser();
                })
                .catch((err) => {
                    this.setState({
                        error: err.description,
                        loading: false,
                    });
                });
        }
    };

    _getPreviousEpics = () => {
        //get all epics generated by the user
        api.get('/api/epic/get-epics').then((data) => {
            const response = data.epics;
            console.log(response);

            this.setState({
                epics: data.epics,
                loadingEpics: false,
            });
        });
    };

    _displayDetails = (epic) => {
        this.setState({
            generatedContent: epic.generated,
        });
    };

    _deleteEpic = (id) => {
        api.post('/api/epic/delete', { id })
            .then((data) => {
                this._getPreviousEpics();
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _handleShortcut = () => {
        api.post('/api/integration/shortcut', { content: this.state.generatedContent })
            .then((data) => {
                this.setState({
                    generatedContent: '',
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    // _handleJira = () => {
    //     api.post('/api/jira/create-issue', { content: this.state.generatedContent })
    //         .then((data) => {
    //             console.log(data);
    //             this.setState({
    //                 generatedContent: '',
    //             });
    //         })
    //         .catch((err) => {
    //             this.setState({
    //                 error: err.description,
    //             });
    //         });
    // };

    _handleTrello = () => {
        //fetch api keys for user
        api.get('/api/integration/get-api-keys').then((data) => {
            // check in response of integrations array
            // if "integrationName:trello" is present, if so then get value of integrationKey
            // if not present then show error message
            // if present then call createTrelloCard with integrationKey as parameter
            const response = data.integrations;
            const trelloApiKey = response.find(
                (item) => item.integrationName === 'trello'
            ).integrationKey;
            if (!trelloApiKey) {
                this.setState({
                    error: 'Please connect to Trello first.',
                });
            } else {
                this.createTrelloCard(trelloApiKey);
            }
        });
    };

    createTrelloCard = (trelloApiKey) => {
        TrelloClient({
            apiKey: trelloApiKey, //'e31d6c23131086016ad8dea770c937e9',
            clientVersion: 1, // number: {1}, {2}, {3}
            apiEndpoint: 'https://api.trello.com', // string: "https://api.trello.com"
            authEndpoint: 'https://trello.com', // string: "https://trello.com"
            intentEndpoint: 'https://trello.com', // string: "https://trello.com"
            authorizeName: 'Epicmatic', // string: "React Trello Client"
            interactive: true,
            authorizePersist: true,
            authorizeType: 'popup',
            authorizeScopeRead: false, // boolean: {true} | {false}
            authorizeScopeWrite: true, // boolean: {true} | {false}
            authorizeScopeAccount: true, // boolean: {true} | {false}
            authorizeExpiration: 'never',
            autoAuthorize: false,
            authorizeButton: false,
            authorizeOnSuccess: () => console.log('Login success!'),
            authorizeOnError: () => console.log('Login error!'),
        });

        Trello.addCard({
            name: 'Epicmatic',
            desc: this.state.generatedContent,
            next: function (card, error) {
                if (error) {
                    // console.log(error);
                } else {
                    // console.log(card);
                }
            },
        });
    };

    // _refreshSubscription = () => {
    //     api.post('/api/payment/refresh-subscription').then((data) => {
    //         this._refreshUser();
    //     });
    // };

    _getIntegrations = () => {
        //fetch api keys for user
        api.get('/api/integration/get-api-keys').then((data) => {
            const response = data.integrations;

            this.setState({
                integrations: response,
            });
        });
    };

    _refreshUser = () => {
        api.post('/api/auth/refresh')
            .then((data) => {
                localStorage.setItem('identity', data.token);
                this.props.setUser();
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _completePayment = () => {
        api.post('/api/payment/create-checkout-session').then((data) => {
            let sessionId = data.session;
            this._handleStripeCheckout(sessionId);
        });
    };

    _handleStripeCheckout = (sessionId) => {
        stripePromise.then((stripe) => {
            stripe.redirectToCheckout({
                sessionId: sessionId,
            });
        });
    };
}

export default Dashboard;
