import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import api from './utils/api';
import { HashLink as Link } from 'react-router-hash-link';

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            topic: '',
            message: '',
            error: '',
            success: '',
            checkbox: false,
        };
    }

    render() {
        return (
            <main className="contact-container container">
                <Helmet>
                    <meta name="robots" content="noindex" />
                    <title>Contact | epicmatic</title>
                </Helmet>
                <div className="contact-form">
                    <div className="contact-txt-big">Say hi.</div>
                    <div className="contact-txt-small">
                        Fill out the form below and we will get back to you shortly.
                    </div>

                    <input
                        type="email"
                        placeholder="email address*"
                        value={this.state.email}
                        onChange={(e) => this._handleChange('email', e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="topic*"
                        maxLength={50}
                        value={this.state.topic}
                        onChange={(e) => this._handleChange('topic', e.target.value)}
                    />
                    <textarea
                        type="text"
                        name="message"
                        placeholder="message*"
                        value={this.state.message}
                        onChange={(e) => this._handleChange('message', e.target.value)}
                        rows={3}
                        minLength={50}
                        required
                    />
                    <label className="checkbox-label checkbox path">
                        <input
                            className="sign-checkbox"
                            type="checkbox"
                            checked={this.state.checkbox}
                            onChange={this._handleCheckboxChange}
                            onKeyPress={this._onKeyPress}
                        />
                        <svg viewBox="0 0 21 21">
                            <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                        </svg>
                        I accept
                        <Link to="/terms" className="home-link" style={{ marginRight: '3px' }}>
                            Terms
                        </Link>
                        and
                        <Link to="/privacy-policy" className="home-link">
                            Privacy Policy
                        </Link>
                        .
                    </label>
                    <button className="form-btn sign-btn" onClick={() => this._handleSubmit()}>
                        send
                    </button>
                    {this.state.success ? <div className="success-txt">{this.state.success}</div> : null}
                    {this.state.error ? <div className="sign-error">{this.state.error}</div> : null}
                </div>
            </main>
        );
    }

    _handleChange = (key, newValue) => {
        this.setState({ [key]: newValue });
    };

    _handleCheckboxChange = () => {
        this.setState((prevState) => ({
            checkbox: !prevState.checkbox,
        }));
    };

    _handleSubmit = () => {
        api.post('/api/contact/send-message', {
            email: this.state.email,
            topic: this.state.topic,
            message: this.state.message,
            checkbox: this.state.checkbox,
        })
            .then((data) => {
                this.setState({
                    email: '',
                    topic: '',
                    message: '',
                    error: '',
                    checkbox: false,
                    success: 'Message sent successfully!',
                });

                setTimeout(() => {
                    this.setState({
                        success: '',
                    });
                }, 5000);
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };
}

export default Contact;
